export const ADD_EDIT_USER_MODAL_TOGGLE = "ADD_EDIT_USER_MODAL_TOGGLE";
export const ADD_EDIT_USER_MODAL_TYPE = "ADD_EDIT_USER_MODAL_TYPE";
export const ADD_EDIT_USER_MODAL_ROW_DATA = "ADD_EDIT_USER_MODAL_ROW_DATA";
export const ADD_EDIT_USER_MODAL_AVATAR = "ADD_EDIT_USER_MODAL_AVATAR";

export const ADD_EDIT_USER_MODAL_NAME = "ADD_EDIT_USER_MODAL_NAME";
export const ADD_EDIT_USER_MODAL_EMAIL = "ADD_EDIT_USER_MODAL_EMAIL";
export const ADD_EDIT_USER_MODAL_DESIGNATION_ID =
  "ADD_EDIT_USER_MODAL_DESIGNATION_ID";
export const ADD_EDIT_USER_MODAL_ROLE = "ADD_EDIT_USER_MODAL_ROLE";
export const ADD_EDIT_USER_MODAL_STATUS = "ADD_EDIT_USER_MODAL_STATUS";
export const ADD_EDIT_USER_MODAL_BLOCK_COMMENTS = "ADD_EDIT_USER_MODAL_BLOCK_COMMENTS";
export const ADD_EDIT_USER_MODAL_ALLOW_EDIT = "ADD_EDIT_USER_MODAL_ALLOW_EDIT";
export const ADD_EDIT_USER_MODAL_ALLOW_DOWNLOAD =
  "ADD_EDIT_USER_MODAL_ALLOW_DOWNLOAD";
export const ADD_EDIT_USER_MODAL_USERNAME = "ADD_EDIT_USER_MODAL_USERNAME";
export const ADD_EDIT_USER_MODAL_COUNTRY_REGION =
  "ADD_EDIT_USER_MODAL_COUNTRY_REGION";
export const ADD_EDIT_USER_MODAL_CONTACT_NUMBER =
  "ADD_EDIT_USER_MODAL_CONTACT_NUMBER";
export const ADD_EDIT_USER_MODAL_NEW_PASSWORD =
  "ADD_EDIT_USER_MODAL_NEW_PASSWORD";
  export const ADD_EDIT_USER_MODAL_IS_LOADING =
  "ADD_EDIT_USER_MODAL_IS_LOADING";
  export const ADD_EDIT_USER_MODAL_ALLOW_FOLDERS_ID = "ADD_EDIT_USER_MODAL_ALLOW_FOLDERS_ID";
export const ADD_EDIT_USER_MODAL_STARTUP_FOLDER =
  "ADD_EDIT_USER_MODAL_STARTUP_FOLDER";
export const ADD_EDIT_USER_MODAL_RESET = "ADD_EDIT_USER_MODAL_RESET";

export const setAddEditUserModalToggle = (addEditUserModalToggle ) => ({
  type: ADD_EDIT_USER_MODAL_TOGGLE,
  payload: { addEditUserModalToggle:addEditUserModalToggle },
});
export const setAddEditUserModalType = (addEditUserModalType ) => ({
  type: ADD_EDIT_USER_MODAL_TYPE,
  payload: { addEditUserModalType:addEditUserModalType },
});

export const setAddEditUserModalRowData = (addEditUserModalRowData ) => ({
  type: ADD_EDIT_USER_MODAL_ROW_DATA,
  payload: { addEditUserModalRowData:addEditUserModalRowData },
});

export const setAddEditUserModalAvatar = (addEditUserModalAvatar) => ({
  type: ADD_EDIT_USER_MODAL_AVATAR,
  payload: { addEditUserModalAvatar:addEditUserModalAvatar },
});

export const setAddEditUserModalName = (addEditUserModalName) => ({
  type: ADD_EDIT_USER_MODAL_NAME,
  payload: { addEditUserModalName:addEditUserModalName },
});

export const setAddEditUserModalEmail = (addEditUserModalEmail) => ({
  type: ADD_EDIT_USER_MODAL_EMAIL,
  payload: { addEditUserModalEmail:addEditUserModalEmail },
});

export const setAddEditUserModalDesignationId = (addEditUserModalDesignationId) => ({
  type: ADD_EDIT_USER_MODAL_DESIGNATION_ID,
  payload: { addEditUserModalDesignationId:addEditUserModalDesignationId },
});

export const setAddEditUserModalRole = (addEditUserModalRole) => ({
  type: ADD_EDIT_USER_MODAL_ROLE,
  payload: { addEditUserModalRole:addEditUserModalRole },
});

export const setAddEditUserModalStatus = (addEditUserModalStatus) => ({
  type: ADD_EDIT_USER_MODAL_STATUS,
  payload: { addEditUserModalStatus:addEditUserModalStatus },
});
export const setAddEditUserModalBlockComments = (addEditUserModalBlockComments) => ({
  type: ADD_EDIT_USER_MODAL_BLOCK_COMMENTS,
  payload: { addEditUserModalBlockComments:addEditUserModalBlockComments },
});

export const setAddEditUserModalAllowEdit = (addEditUserModalAllowEdit) => ({
  type: ADD_EDIT_USER_MODAL_ALLOW_EDIT,
  payload: { addEditUserModalAllowEdit:addEditUserModalAllowEdit },
});

export const setAddEditUserModalAllowDownload = (addEditUserModalAllowDownload) => ({
  type: ADD_EDIT_USER_MODAL_ALLOW_DOWNLOAD,
  payload: { addEditUserModalAllowDownload:addEditUserModalAllowDownload },
});

export const setAddEditUserModalUsername = (addEditUserModalUsername) => ({
  type: ADD_EDIT_USER_MODAL_USERNAME,
  payload: { addEditUserModalUsername:addEditUserModalUsername },
});

export const setAddEditUserModalCountryRegion = (addEditUserModalCountryRegion) => ({
  type: ADD_EDIT_USER_MODAL_COUNTRY_REGION,
  payload: { addEditUserModalCountryRegion:addEditUserModalCountryRegion },
});

export const setAddEditUserModalContactNumber = (addEditUserModalContactNumber) => ({
  type: ADD_EDIT_USER_MODAL_CONTACT_NUMBER,
  payload: { addEditUserModalContactNumber:addEditUserModalContactNumber },
});

export const setAddEditUserModalNewPassword = (addEditUserModalNewPassword) => ({
  type: ADD_EDIT_USER_MODAL_NEW_PASSWORD,
  payload: { addEditUserModalNewPassword:addEditUserModalNewPassword },
});
export const setAddEditUserModalIsLoading = (addEditUserModalIsLoading) => ({
  type: ADD_EDIT_USER_MODAL_IS_LOADING,
  payload: { addEditUserModalIsLoading:addEditUserModalIsLoading },
});
export const setAddEditUserModalAllowFoldersId = (addEditUserModalAllowFoldersId) => ({
  type: ADD_EDIT_USER_MODAL_ALLOW_FOLDERS_ID,
  payload: { addEditUserModalAllowFoldersId:addEditUserModalAllowFoldersId },
});
export const setAddEditUserModalStartupFolder = (addEditUserModalStartupFolder) => ({
  type: ADD_EDIT_USER_MODAL_STARTUP_FOLDER,
  payload: { addEditUserModalStartupFolder:addEditUserModalStartupFolder },
});
export const setAddEditUserModalReset = () => ({
  type: ADD_EDIT_USER_MODAL_RESET,
});


